document.addEventListener('DOMContentLoaded', () => {
  const attributeFlagRadios = document.querySelectorAll('.js-attribute-flag')
  if (attributeFlagRadios.length) {
    const customerArea = document.querySelector('.js-mail-magazine-setting-area-customer')
    const creatorArea = document.querySelector('.js-mail-magazine-setting-area-creator')

    const handleChangeAttributeFlag = () => {
      const currentAttributeFlag = document.querySelector('.js-attribute-flag:checked').dataset.attributeFlag
      if (currentAttributeFlag === 'customer') {
        showArea(customerArea)
        hideArea(creatorArea)
      } else if (currentAttributeFlag === 'creator') {
        showArea(creatorArea)
        hideArea(customerArea)
      }
    }
    const showArea = (area) => area && (area.style.display = 'block')
    const hideArea = (area) => area && (area.style.display = 'none')

    attributeFlagRadios.forEach((attributeFlagRadio) => {
      attributeFlagRadio.addEventListener('change', handleChangeAttributeFlag)
    })
    handleChangeAttributeFlag()
  }
})
