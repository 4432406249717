$(() => {
  const $form = $('.js-original-album-entry-prices')

  if ($form.is('*')) {
    const $albumPrices = $form.find('.js-album-prices')
    const $audioPrices = $form.find('.js-audio-prices')
    const priceList = $audioPrices.data('priceList')
    let selectedAlbumPrice = parseInt($albumPrices.val(), 10)
    let selectedAudioPrice = parseInt($audioPrices.data('selectedPrice'), 10)

    // アルバム価格に応じて収録曲単品価格を変更
    const refreshAlbumPrice = (albumPrice) => {
      const audioPriceList = priceList[albumPrice]
      const options = [$('<option>選択してください</option>')]
      audioPriceList.forEach((audioPrice) => {
        options.push($(`<option value="${audioPrice}" ${audioPrice === selectedAudioPrice ? 'selected' : ''}>${audioPrice}円</option>`))
      })

      selectedAlbumPrice = albumPrice
      selectedAudioPrice = audioPriceList.includes(selectedAudioPrice) ? selectedAudioPrice : undefined

      $audioPrices.html(options)
    }

    $audioPrices.on('change', (e) => selectedAudioPrice = parseInt(e.target.value, 10))
    $albumPrices.on('change', (e) => refreshAlbumPrice(e.target.value))

    // 初期化
    if (!isNaN(selectedAlbumPrice)) refreshAlbumPrice(selectedAlbumPrice)
  }
})
