document.addEventListener('DOMContentLoaded', () => {
  const subscriptionTypeSearchCheckBoxes = document.querySelectorAll('input[name=audio_search\\[subscription_type\\]]')

  if (subscriptionTypeSearchCheckBoxes) {
    subscriptionTypeSearchCheckBoxes.forEach((subscriptionTypeSearchCheckBox) => {
      subscriptionTypeSearchCheckBox.addEventListener('change', (e) => {
        // 定額制プラン契約時は、絞り込みをOFFにする場合は明示的に空文字を送信する必要があるため、送信前にチェックボックスを制御
        const subscriptionTypeOffHiddenCheckBox = document.querySelector('#audio-search-subscription-type-off-hidden')
        if (subscriptionTypeOffHiddenCheckBox) {
          subscriptionTypeOffHiddenCheckBox.checked = !subscriptionTypeOffHiddenCheckBox.checked
        }

        // フォームの送信制御
        e.target.closest('form').submit()
        e.target.disabled = true
      })
    })
  }
})
